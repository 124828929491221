import React from "react";

const Contact = () => {
  return (
    <div className="contact">
      <h3>注意事項 : </h3>
      <p>這是一個練習用的網站，因此請勿避免使用任何個人資料。</p>
      <br />
      <h3>網頁架構 : </h3>
      <p>
        使用React.js作為前端框架，Express、MongoDB、Node.js作為後端服務完成的項目，也就是常見的MERN項目。
        <br />
        樣式主要套用了Bootstrap。也用到了Passport套件來驗證使用者的json web
        token，以及用bcrypt來加密使用者的密碼。
      </p>
      <br />
      <h3>設計發想與使用說明 : </h3>
      <h4>動機 : </h4>
      <p>
        我本人一直有想找時間去日本旅遊的想法，但是也僅限於想想...
        <br />
        之所以會想去日本，是因為我對ACG的興趣。然而不管是遊戲、小說或者漫畫，我都是收藏數位版為主，
        所以其實也只是嚮往去一趟日本，始終沒有真正開始出遊計畫。
        <br />
        如果真的要去，也會因為自己對那些旅遊熱門景點沒有興趣。我不想逛名勝古蹟、也對美食不感興趣。
        只想集中在那些能滿足自己ACG興趣的地點，導致網路上很多的旅遊計畫無法作為參考。
        <br />
        所以我就想著能不能把行程計畫拆成一個一個的「點」，分別列出預算，讓人可以自選感興趣行程，自行拼湊，就像自助餐一樣。最終完善自己的計畫。
        <br />
        所以這個網站可以讓使用者發布行程，不管是提問、或者供人參考，其他使用者可以對行程發表留言，並點讚收藏。
      </p>
      <h4>設計 : </h4>
      <p>
        主要使用紅白配色，是因為本人特別喜歡喝可口可樂，尤其是纖維+口味，就是白底紅字的那一款。
        <br />
        本來網站也想仿照白底紅字配色，但是那樣對使用者的眼睛傷害頗深，所以就改成紅底白字了。
        紅色也換成較深的 rgb(159,45,32)。
      </p>
      <h4>聯絡方式 : </h4>
      <p>信箱: siechengye@gmail.com</p>
    </div>
  );
};

export default Contact;
