import React from "react";

const Footer = () => {
  return (
    <div className="Footer">
      <h2>2023 Cheng-Ye Sie</h2>
    </div>
  );
};

export default Footer;
